<template>
    <b-modal
      id="modal-create-product"
      :title="$t('products.modal-create-title')"
      :ok-title="$t('form.actions.save')"
      :cancel-title="$t('form-create-item.cancel')"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      @ok="handleCreateItem"
    >
      <b-form>
        <b-row>

          <!-- Title -->
          <b-col cols="12">
            <b-form-group>
              <label for="name">{{ $t("form-create-item.title") }}:</label>
              <b-form-input
                id="name"
                :placeholder="$t('products.create-placeholder.title')"
                v-model="productInput.name"
                type="text"
                :state="formError"
              />
            </b-form-group>
          </b-col>

          <!-- Price -->
          <b-col cols="9">
            <b-form-group>
              <label for="price"> {{ $t("form-create-item.price") }}:</label>
              <b-form-input
                id="price"
                :placeholder="$t('products.create-placeholder.price')"
                v-model="productInput.unitPrice"
                type="number"
              />
            </b-form-group>
          </b-col>

          <!-- Currency -->
          <b-col cols="3">
            <b-form-group>
              <label for="price"> {{ $t("form-create-item.coin") }}:</label>
              <b-form-select
                v-model="productInput.currencyCode"
                :filterable="true"
                :options="currencyCo"
                label="moneda"
              />
            </b-form-group>
          </b-col>

          <!-- Headline -->
          <b-col cols="12">
            <b-form-group>
              <label class="mt-2" for="headline">{{
                $t("form-create-item.headline")
              }}</label>
              <b-form-input
                :placeholder="$t('products.create-placeholder.headline')"
                v-model="productInput.headline"
                type="text"
              />
            </b-form-group>
          </b-col>

          <!-- Buy link -->
          <b-col cols="12">
            <b-form-group>
              <label class="mt-2" for="buylink">{{
                $t("form-create-item.buy-link")
              }}</label>
              <b-form-input
                :placeholder="$t('products.create-placeholder.buy-link')"
                v-model="productInput.buyLink"
                type="text"
              />
            </b-form-group>
          </b-col>

          <!-- Description -->
          <b-col cols="12">
            <b-form-group>
              <label class="mt-2" for="description"
                >{{ $t("form-create-item.description") }}:</label
              >
              <quill-editor v-model="productInput.description" />
            </b-form-group>
          </b-col>

          <!-- Image -->
          <b-col cols="12">
            <p class="mt-3">{{ $t("form-create-item.image") }}:</p>
          </b-col>
          <b-col cols="12">
            <div class="border rounded p-2">
              <p class="mb-50">
                {{ $t("form-create-item.image-select") }}
              </p>
              <b-media
                no-body
                vertical-align="center"
                class="d-flex align-items-center flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewEl"
                    :src="imageSrc"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                    style="background: #c0c0c0"
                  />
                </b-media-aside>
                <b-media-body>
                  <div class="d-inline-block d-flex align-items-center pl-2">
                    <b-form-file
                      v-model="itemImage"
                      class="pointer"
                      :accept="FileTypes"
                      :placeholder="$t('form-create-item.image-placeholder')"
                      :browse-text="$t('form-create-item.image-button')"
                    />
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>

          <!-- Locations -->
          <b-col cols="12">
            <validation-provider
              v-slot="validationContext"
              :name="$t('organizations.edit.locations.name')"
            >
              <b-form-group
                :label="$t('organizations.edit.locations.label')"
                label-for="profile-location"
                class="font-weight-bold mt-3"
              >
                <places-map-select
                  v-model="productInput.locations"
                  :placeholder="$t('organizations.edit.locations.placeholder')"
                />

                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          
        </b-row>
      </b-form>
    </b-modal>
</template>

<script>
import ProductsPlaceholder from "@/assets/images/placeholders/light/projects.svg";
import ProductPlaceholder from "@/assets/images/placeholders/light/placeholder-dark.jpeg";
// import { ENABLED_APPS_GETTERS } from "@/store/enabled-apps/enabled-apps-store-constants";
// import { getImageResource } from "@/@core/utils/image-utils";
import { quillEditor } from "vue-quill-editor";
import Service from "@/config/service-identifiers";
//import { AppIDLUT } from "@copernicsw/community-common";
// import { translateTranslationTable } from "@/@core/libs/i18n/utils";
// import { CHAT_ACTIONS } from "@/views/apps/chat/constants/chat-store-constants";
import FileTypes from "@/@core/constants/FileTypes";
import { checkPermissions } from "@/@core/utils/roles-utils";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import Teleport from "vue2-teleport";
import RequestMeetingModal from "../../member/modals/RequestMeetingModal.vue";
import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import PlacesMapSelect from '@core/components/places-map-input/PlacesMapSelect.vue';

export default {
  name: "ProductManageModal",
  components: {
    RequestMeetingModal,
    Teleport,
    quillEditor,
    WidgetActions,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    PlacesMapSelect,
  },
  props: {
    product: { 
      type: Object, 
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: true,
      productInput: {
        currencyCode: "EUR",
        locations: [],
      },
      formError: null,
      itemImage: null,
      imageSrc: null,
      currencyCode: {},
      currencyCo: [],
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});

    return {
      getValidationState,
    };
  },
  computed: {
    // Load data from store
    FileTypes() {
      return FileTypes;
    },
    itemsData() {
      return this.$store.getters.products;
    },
    items() {
      return this.itemsData.unpaginated;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    productPlaceholder() {
      return ProductPlaceholder;
    },
    productsPlaceholder() {
      return ProductsPlaceholder;
    },
    canCreate() {
      return checkPermissions(
        "create",
        "product",
        this.loggedMemberRoles,
        this.collective
      );
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
  },
  watch: {
    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchCode();
    this.isLoading = false;
  },
  methods: {
    // End of filter Methods
    async fetchCode() {
      const response = await this.$store.$service[Service.BackendClient].get(
        "currencies",
        {
          params: {
            communityKey: this.$store.getters.currentCollective.key,
            count: 164,
          },
        }
      );
      this.currencyCode = response.data.data;
      for (let i = 0; i < this.currencyCode.length; i++) {
        this.currencyCo.push(this.currencyCode[i].ISO);
      }
      console.log('currencies loaded');
      return response;
    },
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handleCreateItem(bvModalEvt) {
      // Name is required
      if (!this.productInput.name) {
        bvModalEvt.preventDefault();
        this.formError = false;
        return;
      }
      try {
        await this.$store.dispatch("createItem", {
          item: {
            itemType: "products",
            requestConfig: {
              name: this.productInput.name,
              unitPrice: this.productInput.unitPrice,
              headline: this.productInput.headline,
              description: this.productInput.description,
              currencyCode: this.productInput.currencyCode,
              locations: this.productInput.locations,
              buyLink: this.productInput.buyLink,
            },
          },
          file: this.itemImage,
        });
        this.productInput = {};
        this.formError = null;
        this.itemImage = null;
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('success-message.general-success-create'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("error-message.general-error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
